export default [
  {
    header: 'Configurações',
  },
  {
    title: 'Geral',
    route: 'apps-email',
    icon: 'MailIcon',
    disabled: true,
  },
  {
    id: 'config-prod',
    title: 'Atributos',
    icon: 'MenuIcon',
    route: 'attribute-types',
  },
  {
    title: 'Frete',
    route: 'melhor-envio',
    icon: 'TruckIcon',
    // disabled: true,
  },
  {
    title: 'Pagamentos',
    route: 'payment-config',
    icon: 'CheckSquareIcon',
    children: [
      {
        title: 'Mercado Pago',
        icon: 'DollarSignIcon',
        route: 'mercadopago-config',
      },
      {
        title: 'Stripe',
        icon: 'DollarSignIcon',
        route: 'stripe-config',
      },
      {
        title: 'Paghiper',
        icon: 'DollarSignIcon',
        route: 'paghiper-config',
      },
      {
        title: 'Cielo',
        icon: 'DollarSignIcon',
        route: 'cielo-config',
      },
      {
        title: 'Getnet',
        icon: 'DollarSignIcon',
        route: 'getnet-config',
      },
    ],
  },
  {
    title: 'Contabilidade',
    route: 'accounting',
    icon: 'CalendarIcon',
  },
  {
    title: 'Whatsapp',
    route: 'whatsapp',
    icon: 'MailIcon',
  },
  {
    title: 'Plano',
    route: 'apps-calendar',
    icon: 'CalendarIcon',
    disabled: true,
  },
]
