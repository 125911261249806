export default [

  {
    title: 'Home',
    route: 'dashboard',
    icon: 'HomeIcon',
    disabled: false,
  },
  {
    title: 'Pedidos',
    icon: 'CheckSquareIcon',
    route: 'orders-list',
  },
  {
    title: 'Produtos',
    icon: 'ShoppingBagIcon',
    children: [
      {
        title: 'Listagem',
        icon: 'ShoppingBagIcon',
        route: 'products-list',
      },
      {
        title: 'Notificações',
        icon: 'BellIcon',
        route: '',
      },
      {
        title: 'Favoritos',
        icon: 'HeartIcon',
        route: 'favorites-list',
      },
    ],
  },
  {
    title: 'Descontos',
    icon: 'PercentIcon',
    route: 'discounts-list',
  },
  {
    title: 'Usuários',
    icon: 'UsersIcon',
    route: 'users-list',
  },
]
