export default [
  {
    header: 'Site',
  },
  {
    title: 'Páginas',
    route: 'apps-email',
    icon: 'FileTextIcon',
    disabled: true,
  },
  {
    title: 'Contato',
    icon: 'MailIcon',
    route: null,
    disabled: true,
  },
]
